import React, { FC } from 'react';
import Head from 'next/head';
import {
  useColorByToken,
} from '@/components/common/ThemeContext/useColorByToken';

interface Props {
  colorToken: string;
}

export const MetaThemeColor: FC<Props> = React.memo((props) => {
  const { colorToken } = props;

  const themeColor = useColorByToken(colorToken);

  return (
    <Head>
      <meta name="theme-color" content={themeColor} />
      <meta name="msapplication-navbutton-color" content={themeColor} />
    </Head>
  );
});
