import React, { FC, useMemo } from 'react';
import { useField } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { OptionTypeBase } from 'react-select';
import { cn } from '@/lib/classNames';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { IconInfo } from '@/components/ui/icons/IconInfo';
import { Selectors } from '@/lib/selectors';
import { makeValidator } from '@/controllers/forms/forms.validator';
import { isNotEmpty } from '@/controllers/forms/forms.validator/validationRules';
import {
  useFormFieldContext,
} from '@/components/ui/FormElements/FormField/FormField.context';
import {
  HorizontalPositionMode, ToolTip,
  ToolTipWidthMode, VerticalPositionMode,
} from '@/components/ui/ToolTip';
import { IconDocument } from '@/components/ui/icons/IconDocument';
import { typography } from '@/components/ui/typography';
import styles from './FormField.module.scss';

interface Label {
  text: string;
  for: string;
}

interface Hint {
  text: string;
  url?: string;
}

export interface RenderInputProps {
  id?: string;
  'aria-invalid'?: boolean;
  'aria-disabled': boolean;
  disabled: boolean;
  name: string;
  className?: string;
}

interface Props {
  label?: Label;
  hint?: Hint;
  description?: string;
  renderInput: (props: RenderInputProps) => JSX.Element;
  disabled?: boolean;
  className?: string;
  required?: boolean;
  onChange?: (value?: OptionTypeBase) => void;
  multiple?: boolean;
  name: string;
  validateOnChange?: boolean;
  fieldDataQa?: string;
}

export const FormField: FC<Props> = (props) => {
  const {
    label,
    hint,
    renderInput,
    className,
    disabled = false,
    required,
    multiple,
    name,
    onChange,
    validateOnChange = false,
    description,
    fieldDataQa,
  } = props;

  const { t } = useTranslation([I18N_CODES.validates, I18N_CODES.error]);
  const { inputIdMod } = useFormFieldContext();

  const requiredValidator = required
    ? makeValidator(isNotEmpty, t(`${I18N_CODES.error}:field_should_be_filled`))
    : undefined;

  const { meta } = useField(name, {
    subscription: {
      submitError: true,
      dirtySinceLastSubmit: true,
      touched: true,
      error: true,
      dirty: true,
    },
    validate: requiredValidator,
    multiple,
  });

  const submitError = !meta.dirtySinceLastSubmit && meta.submitError;

  const error = meta.error || submitError;

  const hasError = validateOnChange
    ? Boolean(meta.dirty && error)
    : Boolean(meta.touched && error);

  const inputId = useMemo(() => {
    if (!label?.for) {
      return undefined;
    }

    if (!inputIdMod) {
      return label.for;
    }

    return `${label.for}-${inputIdMod}`;
  }, [inputIdMod, label?.for]);

  return (
    <div
      className={cn(styles.field, className)}
      data-qa={fieldDataQa || `form-field-${inputId}`}
    >
      {Boolean(label?.text || hint) && (
        <label
          htmlFor={inputId}
          data-qa='website-label'
          className={cn(styles.label, 'input-label', { [styles.required]: required })}
        >
          {label?.text}

          {hint && (
            <ToolTip
              text={hint.text}
              widthMode={ToolTipWidthMode.Max}
              horizontalPosition={HorizontalPositionMode.RightSide}
              isManualVerticalPositioning
              verticalPosition={VerticalPositionMode.Bottom}
              className={styles.hint}
            >
              {hint.url
                ? (
                  <a
                    href={hint.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconDocument />
                  </a>
                )
                : (
                  <IconDocument />
                )}
            </ToolTip>
          )}
        </label>
      )}

      {description && (
        <legend className={cn(typography.platformTextSecondary, 'mb-12')}>
          {description}
        </legend>
      )}

      <div className={cn({ 'mb-8': hasError })} data-qa={`input-field-${name}`}>
        {renderInput({
          id: inputId,
          'aria-invalid': hasError || undefined,
          'aria-disabled': disabled,
          disabled,
          name,
          className: cn('input-field', {
            [Selectors.Invalid]: hasError,
            [Selectors.Disabled]: disabled,
          }),
        })}
      </div>

      <div
        className={cn(styles.error, 'input-error')}
        data-qa={`input-error-${name}`}
      >
        {hasError && (
          <>
            <IconInfo />
            <span>{t(`${I18N_CODES.validates}:${error}`)}</span>
          </>
        )}
      </div>

      <OnChange name={name}>
        {(value) => {
          if (onChange) {
            onChange(value);
          }
        }}
      </OnChange>
    </div>
  );
};
