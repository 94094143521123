import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { usePlatformTheme } from '@/components/common/ThemeContext/ThemeContext';
import { colorTokens } from '@/components/ui/colorTokens';
import { ColorTheme } from '@/components/common/ThemeContext/typedefs';

const getVariableName = (colorToken: string): string => colorToken
  .replace('var(', '')
  .replace(')', '');

const readColor = (colorToken: string): string | null => {
  // eslint-disable-next-line @mate-academy/frontend/restrict-window-usage
  if (typeof window === 'undefined') {
    return null;
  }

  const rootStyles = getComputedStyle(document.documentElement);

  return rootStyles.getPropertyValue(getVariableName(colorToken)).trim();
};

export const useColorByToken = (
  colorToken: string,
  defaultColor = '',
): string => {
  const { colorTheme } = usePlatformTheme();
  const [color, setColor] = useState<string>(
    () => readColor(colorToken) || defaultColor,
  );

  useEffect(() => {
    setColor(readColor(colorToken) || defaultColor);
  }, [colorTheme, colorToken, defaultColor]);

  return color;
};

export type ColorTokenKey = keyof typeof colorTokens;

type FallbackColor = Record<ColorTheme, string>;

export type ColorsWithFallback = {
  colorToken: ColorTokenKey;
  fallback: FallbackColor;
};

type ColorsMap = Map<ColorTokenKey, string>;

export type UseColorsByTokensReturnType = {
  colors: ColorsMap;
  getColorByToken: (token: ColorTokenKey) => string;
};

type Options = {
  colorsWithFallback: ColorsWithFallback[];
};

export const useColorsByTokens = (
  options: Options,
): UseColorsByTokensReturnType => {
  const { colorsWithFallback } = options;
  const { colorTheme } = usePlatformTheme();

  const colors = useRef<ColorsMap>(new Map());

  useEffect(() => {
    colorsWithFallback.forEach((tokenWithFallback) => {
      const {
        colorToken,
        fallback,
      } = tokenWithFallback;

      const fallbackColor = fallback[colorTheme];

      const colorHex = readColor(colorTokens[colorToken]) || fallbackColor;

      colors.current.set(colorToken, colorHex);
    });
  }, [colorsWithFallback, colorTheme]);

  const getColorByToken = useCallback((
    token: keyof typeof colorTokens,
  ) => colors.current.get(token) ?? '', []);

  return useMemo(() => ({
    colors: colors.current,
    getColorByToken,
  }), [
    getColorByToken,
  ]);
};
