import { useCallback, useState } from 'react';
import { emptyFunction } from '@/lib/helpers/functional';

interface Options {
  initialState?: boolean;
  onChangeState?: (v: boolean) => void;
}

type IsOpen = boolean;
type Close = () => void;
type Open = () => void;
type Toggle = () => void;

const DEFAULT_OPTIONS: Required<Options> = {
  initialState: false,
  onChangeState: emptyFunction,
};

/**
 * @deprecated Use useIsOpenState instead.The type of return value was changed from array to object.
 * @see [useIsOpenState](./useIsOpenState.ts)
 */
export const useIsOpenStateDeprecated = (
  options: Options = DEFAULT_OPTIONS,
): [IsOpen, Close, Open, Toggle] => {
  const {
    onChangeState = DEFAULT_OPTIONS.onChangeState,
    initialState = DEFAULT_OPTIONS.initialState,
  } = options;

  const [isOpen, setIsOpen] = useState(initialState);

  const close = useCallback(() => {
    setIsOpen(false);
    onChangeState(false);
  }, [onChangeState]);

  const open = useCallback(() => {
    setIsOpen(true);
    onChangeState(true);
  }, [onChangeState]);

  const toggle = useCallback(() => {
    setIsOpen((prev) => {
      onChangeState(!prev);

      return !prev;
    });
  }, [onChangeState]);

  return [
    isOpen,
    close,
    open,
    toggle,
  ];
};
